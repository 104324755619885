import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore"; // Import Firestore

const firebaseConfig = {
  apiKey: "AIzaSyD_3pCIi74VBWMjbUqxRg-IbPPvS4V__2o",
  authDomain: "excelcloyd.firebaseapp.com",
  projectId: "excelcloyd",
  storageBucket: "excelcloyd.appspot.com",
  messagingSenderId: "555655886572",
  appId: "1:555655886572:web:aecbe7e6e5725f762a57c9",
  measurementId: "G-W3LWWTEMYK" // Optional
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Initialize Firebase Storage and get a reference to the service
export const storage = getStorage(app);

// Initialize Firestore and get a reference to the service
export const db = getFirestore(app); // New Firestore reference
