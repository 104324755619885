import React, { useEffect, useState } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';  // Import necessary Firebase Storage methods
import { storage } from './firebaseConfig';  // Firebase config is properly set up

const FileDownloader = ({ folderName, dateFolder }) => {
  const [fileUrl, setFileUrl] = useState('');  // State for file download URL
  const [error, setError] = useState('');      // State for error messages
  const [loading, setLoading] = useState(true); // State for loading

  useEffect(() => {
    const fetchFileUrl = async () => {
      try {
        // Ensure folderName and dateFolder are provided
        if (!folderName || !dateFolder) {
          throw new Error('Folder name or date is missing.');
        }

        // Encode the file path to ensure special characters are handled properly
        const encodedFolderName = encodeURIComponent(folderName);
        const encodedDateFolder = encodeURIComponent(dateFolder);

        // Create a reference to the file in Firebase Storage
        const fileRef = ref(storage, `uploads/${encodedFolderName}/${encodedDateFolder}/2024.xlsx`);

        // Get the download URL for the file
        const url = await getDownloadURL(fileRef);
        setFileUrl(url);  // Set the URL for the file
      } catch (err) {
        // Catch and display any errors encountered
        setError(`Error fetching file: ${err.message || 'Unknown error occurred'}`);
      } finally {
        setLoading(false);  // Stop the loading state once the process is complete
      }
    };

    // Fetch file URL when the component is mounted or folderName/dateFolder changes
    fetchFileUrl();
  }, [folderName, dateFolder]);

  return (
    <div>
      {loading ? (
        // Show loading message while waiting for the URL
        <p>Loading file, please wait...</p>
      ) : error ? (
        // Show error message if something goes wrong
        <p style={{ color: 'red' }}>{error}</p>
      ) : (
        // Render the download link if everything goes well
        <a
          href={fileUrl}
          download="2024.xlsx"  // Fixed download name
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'blue', textDecoration: 'underline' }}
        >
          Download File
        </a>
      )}
    </div>
  );
};

export default FileDownloader;
