import React, { useState } from 'react';
import { storage, db } from './firebaseConfig'; // Import Firebase storage and Firestore
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; // Firebase Storage functions
import { collection, addDoc } from 'firebase/firestore'; // Firestore functions

const FileUploader = () => {
  const [file, setFile] = useState(null); // State to store the selected file
  const [uploadStatus, setUploadStatus] = useState(''); // State to store upload status
  const [customerName, setCustomerName] = useState(''); // State for customer name
  const [phoneNumber, setPhoneNumber] = useState(''); // State for phone number
  const [fileDate, setFileDate] = useState(''); // State for date

  // Handle file selection
  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Set the selected file
  };

  // Store export history in Firestore
  const storeExportHistory = async (exportData) => {
    try {
      await addDoc(collection(db, 'exportHistory'), exportData); // Save export data in Firestore
      console.log('Export history saved successfully');
    } catch (e) {
      console.error('Error saving export history: ', e);
    }
  };

  // Handle file upload to Firebase Storage
  const handleFileUpload = () => {
    if (!file || !customerName || !phoneNumber || !fileDate) {
      alert('Please provide all the required information (file, customer name, phone number, date)');
      return;
    }

    // Format the date and create a unique filename
    const formattedDate = fileDate.replace(/\//g, '_'); // Format date to use in file name
    const encodedCustomerName = encodeURIComponent(customerName); // Encode customer name for URL-safe format
    const fileName = `${encodedCustomerName}_${phoneNumber}_${formattedDate}.xlsx`; // Create file name

    const storageRef = ref(storage, `uploads/${fileName}`); // Create a reference to the file path in Firebase Storage
    const uploadTask = uploadBytesResumable(storageRef, file); // Upload the file

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Progress function to display upload status
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setUploadStatus(`Upload is ${progress}% done`);
      },
      (error) => {
        // Error function for handling upload errors
        console.error('Error uploading file:', error);
        setUploadStatus('Failed to upload');
      },
      () => {
        // Complete function: get download URL after upload is successful
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setUploadStatus(`File uploaded successfully! File URL: ${url}`);

          // Save export history to Firestore
          const exportData = {
            customerName: customerName,
            phoneNumber: phoneNumber,
            exportDate: formattedDate,
            fileUrl: url, // Store the file download URL in Firestore
            userName: 'admin', // Assuming this is the admin user uploading
          };
          storeExportHistory(exportData); // Save export history to Firestore
        });
      }
    );
  };

  return (
    <div>
      <h2>Upload your file</h2>

      {/* Input for customer name */}
      <input 
        type="text" 
        placeholder="Customer Name" 
        value={customerName} 
        onChange={(e) => setCustomerName(e.target.value)} 
        required 
      />

      {/* Input for phone number */}
      <input 
        type="text" 
        placeholder="Phone Number" 
        value={phoneNumber} 
        onChange={(e) => setPhoneNumber(e.target.value)} 
        required 
      />

      {/* Input for file date */}
      <input 
        type="date" 
        placeholder="Date" 
        value={fileDate} 
        onChange={(e) => setFileDate(e.target.value)} 
        required 
      />

      {/* File selection input */}
      <input type="file" onChange={handleFileChange} required />

      {/* Button to trigger the file upload */}
      <button onClick={handleFileUpload}>Upload File</button>

      {/* Display upload status */}
      {uploadStatus && <p>{uploadStatus}</p>}
    </div>
  );
};

export default FileUploader;
