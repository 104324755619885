import React, { useEffect, useState, useCallback } from 'react'; // Import useCallback
import { FaDownload, FaSearch } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage'; // Import Firebase Storage functions
import './ExportHistory.css';

const translations = {
  en: {
    title: "Export History",
    searchPlaceholder: "Search by customer name, file name, or date",
    customerName: "Customer Name",
    fileName: "File Name",
    exportDate: "Export Date",
    userName: "User Name",
    download: "Download",
    noResults: "No results found",
    returnToDashboard: "Return to Dashboard",
    errorMessage: "An error occurred during the download process.",
    validationMessage: "Customer name, phone number, and date are required.",
  },
  ku: {
    title: "مێژووی ناردن",
    searchPlaceholder: "گەڕان بەپێی ناوی کڕیار، ناوی فایل، یان ڕۆژ",
    customerName: "ناوی کڕیار",
    fileName: "ناوی فایل",
    exportDate: "ڕۆژی ناردن",
    userName: "ناوی بەکارهێنەر",
    download: "داگرتن",
    noResults: "هیچ ئەنجامی نەدۆزرایەوە",
    returnToDashboard: "گەڕانەوە بۆ داشبۆرد",
    errorMessage: "هەڵە لە کاتی داگرتنی فایلی ڕوویدا.",
    validationMessage: "ناوی کڕیار، ژمارەی تەلەفۆن، یان ڕۆژەکان پێویستن",
  },
};

function ExportHistory() {
  const [files, setFiles] = useState([]); // State to store file details from Firebase Storage
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const language = location.state?.language || 'ku'; // Default to Kurdish (ku)

  const currentTranslation = translations[language];

  const storage = getStorage(); // Initialize Firebase Storage

  // Fetch files from Firebase Storage using useCallback to ensure stable reference
  const fetchFilesFromStorage = useCallback(async () => {
    const listRef = ref(storage, 'uploads/'); // Reference to the 'uploads/' folder
    try {
      const res = await listAll(listRef); // List all files in the 'uploads/' folder
      const fileData = await Promise.all(
        res.items.map(async (itemRef) => {
          const fileUrl = await getDownloadURL(itemRef); // Get the download URL for each file
          return {
            name: itemRef.name, // File name
            url: fileUrl, // File URL
          };
        })
      );
      setFiles(fileData); // Store files in state
      setLoading(false); // Turn off loading indicator
    } catch (error) {
      console.error('Error fetching files:', error);
      setErrorMessage(currentTranslation.errorMessage);
      setLoading(false); // Turn off loading indicator in case of error
    }
  }, [storage, currentTranslation.errorMessage]); // Memoize the function with useCallback

  useEffect(() => {
    fetchFilesFromStorage(); // Fetch files when the component mounts
  }, [fetchFilesFromStorage]); // Pass fetchFilesFromStorage as a dependency

  // Navigate back to the dashboard
  const handleReturnToDashboard = () => {
    navigate('/dashboard', { state: { language } }); // Navigate back to the dashboard with the selected language
  };

  // Filter files based on the search query
  const filteredFiles = files.filter((file) => {
    const query = searchQuery.toLowerCase();
    return file.name.toLowerCase().includes(query); // Filter by file name
  });

  if (loading) {
    return <p>Loading files...</p>;
  }

  return (
    <div className="export-history-container">
      <h1>{currentTranslation.title}</h1>
      {errorMessage && <p className="error-message">{errorMessage}</p>}

      {/* Search bar with icon */}
      <div className="search-bar-container">
        <FaSearch className="search-icon" />
        <input
          type="text"
          placeholder={currentTranslation.searchPlaceholder}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-bar"
        />
      </div>

      {/* Table Header */}
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>{currentTranslation.customerName}</th>
              <th>{currentTranslation.fileName}</th>
              <th>{currentTranslation.download}</th>
            </tr>
          </thead>
          <tbody>
            {filteredFiles.length > 0 ? (
              filteredFiles.map((file, index) => (
                <tr key={index}>
                  <td>{file.name.split('_')[0] || currentTranslation.customerName}</td>
                  <td>{file.name}</td>
                  <td>
                    <FaDownload
                      onClick={() => window.open(file.url, '_blank')}
                      style={{ cursor: 'pointer', color: '#007bff' }}
                      title={`${currentTranslation.download} ${file.name}`}
                      aria-label={`${currentTranslation.download} ${file.name}`}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" style={{ textAlign: 'center', padding: '20px' }}>
                  {currentTranslation.noResults}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <button onClick={handleReturnToDashboard} className="return-button">
        {currentTranslation.returnToDashboard}
      </button>
    </div>
  );
}

export default ExportHistory;
