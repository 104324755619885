import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { signInAnonymously, onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import Login from './Login';
import MyDashboard from './MyDashboard';
import ExportHistory from './ExportHistory';
import FileUploader from './FileUploader';
import FileDownloader from './FileDownloader';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state to wait for auth check
  const [loggedInUser, setLoggedInUser] = useState(null); // Initially null
  const [exportHistory, setExportHistory] = useState(() => {
    const savedHistory = localStorage.getItem('exportHistory');
    return savedHistory ? JSON.parse(savedHistory) : [];
  });
  const [language, setLanguage] = useState('ku'); // Language state, default to 'ku' for Kurdish

  const navigate = useNavigate();

  // Monitor Firebase Auth state and set the logged-in user accordingly
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const savedUser = sessionStorage.getItem('loggedInUser');
        if (savedUser) {
          setLoggedInUser(JSON.parse(savedUser));
          setIsLoggedIn(true);
        } else {
          setLoggedInUser({ username: 'Anonymous' });
          setIsLoggedIn(true);
        }
      } else {
        setIsLoggedIn(false);
        setLoggedInUser(null);
        navigate('/login'); // Redirect to login if not authenticated
      }
      setLoading(false); // Finished checking auth state, stop loading
    });

    return () => unsubscribe(); // Unsubscribe from the Firebase listener when the component unmounts
  }, [navigate]);

  // Firebase Anonymous Authentication on initial load if no user is logged in
  useEffect(() => {
    if (!loggedInUser) {
      signInAnonymously(auth)
        .then(() => {
          console.log('Signed in anonymously');
        })
        .catch((error) => {
          console.error('Error signing in anonymously:', error);
        });
    }
  }, [loggedInUser]);

  // Handle login success and store only minimal user data
  const handleLoginSuccess = useCallback(
    (user) => {
      const userData = {
        username: user.username, // Store only necessary fields
        userId: user.userId,
      };

      setLoggedInUser(userData);
      sessionStorage.setItem('loggedInUser', JSON.stringify(userData)); // Use sessionStorage to limit data storage
      navigate('/dashboard');
    },
    [navigate]
  );

  // Logout function to clear session storage and navigate back to login
  const handleLogout = useCallback(() => {
    setIsLoggedIn(false);
    setLoggedInUser(null);
    sessionStorage.removeItem('loggedInUser'); // Clear sessionStorage
    navigate('/login');
  }, [navigate]);

  const handleExport = useCallback(
    (exportData) => {
      const updatedHistory = [...exportHistory, exportData];
      setExportHistory(updatedHistory);
      localStorage.setItem('exportHistory', JSON.stringify(updatedHistory)); // Save export history to localStorage
    },
    [exportHistory]
  );

  // Auto logout after 5 minutes of inactivity
  useEffect(() => {
    let timeout;

    const resetTimeout = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        console.log('Auto logout due to inactivity');
        handleLogout();
      }, 5 * 60 * 1000); // 5 minutes
    };

    window.addEventListener('mousemove', resetTimeout);
    window.addEventListener('keydown', resetTimeout);

    resetTimeout();

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', resetTimeout);
      window.removeEventListener('keydown', resetTimeout);
    };
  }, [handleLogout]);

  if (loading) {
    // Show loading spinner while checking authentication state
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <Routes>
      {/* Login Route */}
      <Route
        path="/login"
        element={<Login onLoginSuccess={handleLoginSuccess} />}
      />

      {/* Dashboard Route */}
      <Route
        path="/dashboard"
        element={
          <MyDashboard
            isLoggedIn={isLoggedIn}
            loggedInUser={loggedInUser}
            onExport={handleExport}
            onLogout={handleLogout}
            language={language} // Pass language state to Dashboard
            setLanguage={setLanguage} // Allow Dashboard to change language
          />
        }
      />

      {/* Export History Route */}
      <Route
        path="/export-history"
        element={
          <ExportHistory 
            exportData={exportHistory} 
            language={language} // Pass language to ExportHistory
          />
        }
      />

      {/* File Upload Route */}
      <Route
        path="/upload"
        element={
          <div>
            <h1>Upload Your File</h1>
            <FileUploader />
          </div>
        }
      />

      {/* File Download Route */}
      <Route
        path="/download"
        element={
          <div>
            <h1>Download Your File</h1>
            <FileDownloader />
          </div>
        }
      />
    </Routes>
  );
}

export default App;
