import React, { useState, useEffect } from 'react';
import './Login.css';

function Login({ onLoginSuccess }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Automatically log in if user is already stored in localStorage
  useEffect(() => {
    try {
      const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
      
      // Check if valid user data exists
      if (loggedInUser && loggedInUser.username) {
        onLoginSuccess(loggedInUser); // Automatically log them in
      }
    } catch (error) {
      console.error("Error parsing stored user data:", error);
      // If JSON.parse fails, clear potentially invalid localStorage data
      localStorage.removeItem('loggedInUser');
    }
  }, [onLoginSuccess]);

  // Handle login logic
  const handleLogin = (e) => {
    e.preventDefault();

    // Trim username and password to avoid leading/trailing spaces
    const trimmedUsername = username.trim();
    const trimmedPassword = password.trim();

    // Validate inputs
    if (!trimmedUsername || !trimmedPassword) {
      setErrorMessage('Username and password are required.');
      setSuccessMessage(''); // Clear any success messages
      return;
    }

    // Placeholder authentication (you can replace this with actual auth logic)
    if (trimmedUsername === 'admin' && trimmedPassword === '1234') {
      const user = { username: trimmedUsername };

      // Store user object in localStorage as a string
      localStorage.setItem('loggedInUser', JSON.stringify(user));

      // Clear previous error messages
      setErrorMessage('');
      setSuccessMessage('Login successful!');
      
      // Simulate a redirect (or call your routing logic)
      setTimeout(() => {
        onLoginSuccess(user); // Call the login success handler
      }, 1000); // Simulate a brief delay before redirecting
    } else {
      setErrorMessage('Invalid login credentials. Please try again.');
      setSuccessMessage(''); // Clear success message if login fails
    }
  };

  return (
    <div className="login-container">
      <div className="login-form-container">
        <h2 className="login-title">Login</h2>
        
        <form onSubmit={handleLogin}>
          {/* Display error or success messages */}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          {successMessage && <p className="success-message">{successMessage}</p>}
          
          <input
            type="text"
            className="login-input"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            className="login-input"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          
          <button type="submit" className="login-button">Login</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
