import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import './Dashboard.css';
import logo from './DyarCOLogov2.png';
import { storage } from './firebaseConfig';  // Import Firebase Storage
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

// Helper function to round to 2 decimal places
const roundToTwo = (num) => Math.round(num * 100) / 100;

const translations = {
  en: {
    title: "Listings",
    customerName: "Customer Name",
    phoneNumber: "Phone Number",
    nameCode: "Name and Code (e.g., glass 9001-2)",
    packageQuantity: "Package Quantity",
    individualPrice: "Price of 1 Individual Thing ($)",
    packageAmount: "Amount of Pieces in Package",
    discount: "Discount (IQD)",
    total: "Total (USD)",
    addListing: "Add Listing",
    removeListing: "Remove",
    undo: "Undo",
    exportToExcel: "Export to Excel",
    listingAdded: "Added successfully",
    allFieldsRequired: "All fields are required",
    headers: [
      "Customer Name",
      "Name and Code",
      "Package Quantity",
      "Individual Price",
      "Pieces per package",
      "Remove",
    ],
  },
  ku: {
    title: "لیستەکان",
    customerName: "ناوی کریار",
    phoneNumber: "ژمارەی تەلەفۆن",
    nameCode: "ناو و کۆد (بە نموونە، شوشە 9001-2)",
    packageQuantity: "ژ. کارتۆن",
    individualPrice: "نرخی یەک پارچە",
    packageAmount: "عددی پارچە لە ١ کارتۆن",
    discount: "داشکاندن (IQD)",
    total: "کۆی گشتی (USD)",
    addListing: "زیادکردنی لیست",
    removeListing: "لابردن",
    undo: "گەڕاندنەوە",
    exportToExcel: "ناردن بۆ ئێکسڵ",
    listingAdded: "بەسەرکەوتووی زیادکرا",
    allFieldsRequired: "هەموو خانەکان پێویستە پڕ بکرێنەووە",
    headers: [
      "ناوی کڕیار",
      "ناو و کۆد",
      "ژ. کارتۆن",
      "نرخی یەک پارچە",
      "عددی پارچە لە ١ کارتۆن",
      "لابردن",
    ],
  },
};

function MyDashboard({ loggedInUser, isLoggedIn, onExport, onLanguageChange }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [message, setMessage] = useState(''); // Message for export status
  const [excelData, setExcelData] = useState([]);
  const [language, setLanguage] = useState('ku');
  const [customerName, setCustomerName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [nameCode, setNameCode] = useState('');
  const [packageQuantity, setPackageQuantity] = useState('');
  const [individualPrice, setIndividualPrice] = useState('');
  const [packageAmount, setPackageAmount] = useState('');
  const [discount, setDiscount] = useState(0);  // Discount field
  const [total, setTotal] = useState(0);  // Total field
  const [listings, setListings] = useState([]);
  const [lastRemoved, setLastRemoved] = useState(null);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [isLoadingExchangeRate, setIsLoadingExchangeRate] = useState(true);
  const [exchangeRateError, setExchangeRateError] = useState(null);

  const navigate = useNavigate();

  // Handle navigation and exchange rate fetching
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');  // Ensure the user is redirected to the login page if not logged in
    } else if (!exchangeRate) {
      fetchExchangeRate();  // Fetch exchange rate if not already fetched
    }
  }, [isLoggedIn, exchangeRate, navigate]);

  // Language Change Function
  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage); // Update the state with the new language
  };

  // Load Excel Data
  useEffect(() => {
    const loadExcelData = async () => {
      try {
        const workbook = new ExcelJS.Workbook();
        const response = await fetch(`${process.env.PUBLIC_URL}/data base 1400.xlsx`);
        const arrayBuffer = await response.arrayBuffer();
        await workbook.xlsx.load(arrayBuffer);
        const worksheet = workbook.getWorksheet(1);

        const data = worksheet.getSheetValues().slice(2).map(row => ({
          name: row[1],
          code: row[2],
        }));

        setExcelData(data);
      } catch (error) {
        console.error('Failed to load Excel data:', error);
      }
    };

    loadExcelData();
  }, []);

  // Calculate total (without applying discount) whenever listings change
  useEffect(() => {
    let newTotal = listings.reduce((sum, listing) => {
      const quantity = parseInt(listing.packageQuantity);
      const price = parseFloat(listing.individualPrice.replace('$', ''));
      const packageAmount = parseInt(listing.packageAmount);
      const subtotal = quantity * price * packageAmount;
      return sum + subtotal;
    }, 0);

    setTotal(roundToTwo(newTotal));  // Set total without discount
  }, [listings]);

  const fetchExchangeRate = async () => {
    setIsLoadingExchangeRate(true);
    setExchangeRateError(null);

    try {
      const response = await fetch('https://iqd-spartan-rates-4cae312889ad.herokuapp.com/api/exchange_rate');
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

      const data = await response.json();
      const truncatedRate = parseInt(data.exchange_rate.replace(',', '').slice(0, 4));
      setExchangeRate(truncatedRate);
    } catch (error) {
      setExchangeRateError('Failed to fetch exchange rate. Please try again later.');
    } finally {
      setIsLoadingExchangeRate(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('loggedInUser');  // Clear local storage
    setShowDropdown(false);
    navigate('/login');  // Redirect to login
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleViewExportHistory = () => {
    setShowDropdown(false);
    navigate('/export-history'); // Pass language to ExportHistory
  };

  const handleNameCodeChange = (e) => {
    const query = e.target.value.toLowerCase().normalize('NFC').trim();
    setNameCode(query);

    if (query.length > 0) {
      const filteredSuggestions = excelData.filter(item => {
        const itemName = item.name ? item.name.toLowerCase().normalize('NFC') : '';
        const itemCode = item.code ? item.code.toLowerCase().normalize('NFC') : '';

        if (query.includes('-')) {
          const parts = query.split("-");
          if (parts.length === 2) {
            const [part1, part2] = parts;
            return itemCode.includes(`${part1}-${part2}`) || itemCode.includes(`${part2}-${part1}`);
          }
        }

        return itemName.includes(query) || itemCode.includes(query);
      });

      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setNameCode(`${suggestion.name} ${suggestion.code}`);
    setSuggestions([]);
  };

  const addListing = () => {
    const currentTranslation = translations[language] || translations['en'];
    if (!customerName || !nameCode || !packageQuantity || !individualPrice || !packageAmount || !phoneNumber) {
      setMessage(currentTranslation.allFieldsRequired);
      return;
    }

    const newListing = {
      customerName,
      phoneNumber,  // Ensure this is set here
      nameCode,
      packageQuantity,
      individualPrice: `$${individualPrice}`,
      packageAmount,
    };

    setListings([...listings, newListing]);
    setNameCode('');
    setPackageQuantity('');
    setIndividualPrice('');
    setPackageAmount('');
    setMessage(currentTranslation.listingAdded);

    // Clear the message after 3 seconds
    setTimeout(() => {
      setMessage('');
    }, 3000);
  };

  const removeListing = (index) => {
    const removed = listings.splice(index, 1);
    setListings([...listings]);
    setLastRemoved(removed[0]);
  };

  const undoRemove = () => {
    if (lastRemoved) {
      setListings([...listings, lastRemoved]);
      setLastRemoved(null);
    }
  };

  const exportToExcel = async () => {
    if (listings.length === 0) {
      setMessage('No listings to export');
      return;
    }
  
    try {
      const workbook = new ExcelJS.Workbook();
      const response = await fetch('/template maxzan.xlsx');
      if (!response.ok) throw new Error('Network response was not ok');
      const arrayBuffer = await response.arrayBuffer();
      await workbook.xlsx.load(arrayBuffer);
      const worksheet = workbook.getWorksheet(1);
  
      // Correct date formatting (use slashes)
      const currentDate = new Date().toLocaleDateString('en-GB');
      worksheet.getCell('A2').value = currentDate;
      worksheet.getCell('A2').font = { name: 'Source Sans Pro', size: 24, bold: true };
  
      // Use customerName and phoneNumber from the first listing
      const firstCustomerName = listings[0].customerName || "Unknown Customer";
      const phoneNumber = listings[0].phoneNumber || "Unknown Phone";
  
      // Set the customer name in F2
      worksheet.getCell('F2').value = firstCustomerName;
      worksheet.getCell('F2').font = { name: 'Source Sans Pro', size: 24, bold: true };
  
      // Generate a dynamic filename using underscores in the file name
      const fileName = `${firstCustomerName}_${phoneNumber}_${currentDate.replace(/\//g, '_')}.xlsx`;
      console.log("Generated file name:", fileName);
  
      const baseCode = "C1-2570";
      const lastNumber = parseInt(baseCode.split('-')[1]);
      const listingCode = `ڕقم قائیمە [C1-${lastNumber + listings.length}]`;
      worksheet.getCell('B2').value = listingCode;
      worksheet.getCell('B2').alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.mergeCells('B2:D2');
  
      let rowIndex = 4;
      let totalSum = 0;
  
      listings.forEach((listing, index) => {
        const row = worksheet.getRow(rowIndex);
        const numberOfPieces = parseInt(listing.packageQuantity);
        const pricePerPiece = parseFloat(listing.individualPrice.replace('$', ''));
        const numberOfPackages = parseInt(listing.packageAmount);
  
        // Validate calculations
        if (isNaN(numberOfPieces) || isNaN(pricePerPiece) || isNaN(numberOfPackages)) {
          console.error('Invalid data in listings');
          setMessage('Invalid data in listings, please check input values.');
          return;
        }
  
        // B4 = D4 * E4 (total number of pieces)
        const totalItems = numberOfPieces * numberOfPackages;
        row.getCell('B').value = totalItems;
  
        // A4 = B4 * C4 (total in dollars)
        const totalInDollars = roundToTwo(totalItems * pricePerPiece);
        row.getCell('A').value = totalInDollars;
  
        // Add the other details to the row
        totalSum = roundToTwo(totalSum + totalInDollars);
  
        row.getCell('F').value = listing.nameCode;
        row.getCell('E').value = numberOfPackages;
        row.getCell('C').value = roundToTwo(pricePerPiece);
        row.getCell('D').value = numberOfPieces;
        row.getCell('G').value = index + 1;
        row.commit();
        rowIndex += 1;
      });
  
      // Add total row
      const totalRow = worksheet.getRow(rowIndex);
      totalRow.getCell('A').value = roundToTwo(totalSum);
      totalRow.getCell('A').font = { name: 'Source Sans Pro', size: 20, bold: false };
      totalRow.getCell('F').value = "مجموع";
      totalRow.getCell('G').value = "*+*";
      totalRow.commit();
  
      rowIndex += 1;
  
      // Add exchange rate row
      const exchangeRow = worksheet.getRow(rowIndex);
      const exchangeTotal = roundToTwo(totalSum * exchangeRate);
      exchangeRow.getCell('A').value = exchangeTotal;
      exchangeRow.getCell('F').value = `${totalSum} * ${exchangeRate}`;
      exchangeRow.getCell('G').value = "*+*";
      exchangeRow.commit();
  
      rowIndex += 1;
  
      // If a discount is applied, include the discount and final total
      if (parseFloat(discount) > 0) {
        const discountRow = worksheet.getRow(rowIndex);
        const discountValue = roundToTwo(parseFloat(discount) || 0);
        discountRow.getCell('A').value = discountValue;
        discountRow.getCell('F').value = "داشکاندن";  // Discount label
        discountRow.getCell('G').value = "*+*";
        discountRow.commit();
  
        rowIndex += 1;
  
        const finalTotalRow = worksheet.getRow(rowIndex);
        const finalTotal = roundToTwo(exchangeTotal - discountValue);
        finalTotalRow.getCell('A').value = finalTotal;
        finalTotalRow.getCell('F').value = "مجموع";  // Final total label
        finalTotalRow.getCell('G').value = "*+*";
        finalTotalRow.commit();
      }
  
      // Save the Excel file as a Blob
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, fileName);
  
      // Upload to Firebase Storage
      const storageRef = ref(storage, `uploads/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, blob);
  
      uploadTask.on('state_changed',
        (snapshot) => {
          console.log(`Upload is ${snapshot.bytesTransferred / snapshot.totalBytes * 100}% done`);
        },
        (error) => {
          console.error('Failed to upload file:', error);
          setMessage('Failed to upload Excel file');
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File uploaded successfully. Download URL:", downloadURL);
            const exportData = {
              customerName: firstCustomerName,
              phoneNumber,
              fileName,
              exportDate: currentDate,
              userName: loggedInUser?.username || "Unknown",
              fileUrl: downloadURL,
            };
  
            onExport(exportData);
            setMessage('Excel file exported and uploaded successfully');
  
            // Clear the message after 3 seconds
            setTimeout(() => {
              setMessage('');
            }, 3000);
          });
        }
      );
    } catch (error) {
      console.error('Failed to export to Excel:', error);
      setMessage(`Failed to export Excel file: ${error.message}`);
  
      // Clear the message after 3 seconds
      setTimeout(() => {
        setMessage('');
      }, 3000);
    }
  };

  const currentTranslation = translations[language] || translations['en'];

  return (
    <div className={`container ${language === 'ku' ? 'rtl' : ''}`}>
      <div className="user-info">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="black"
          width="18px"
          height="18px"
          style={{ marginRight: '5px', cursor: 'pointer' }}
          onClick={toggleDropdown}
        >
          <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
        </svg>
        <span onClick={toggleDropdown} style={{ cursor: 'pointer', color: 'black' }}>
          {loggedInUser?.username}
        </span>
        {showDropdown && (
          <div className="dropdown-menu">
            <button onClick={handleViewExportHistory}>View Export History</button>
            <button onClick={handleLogout}>Logout</button>
          </div>
        )}
      </div>

      <div className="form-container">
        <img src={logo} alt="Company Logo" className="logo" />

        <div className="language-switcher">
          <button
            className={`language-button ${language === 'en' ? 'selected' : ''}`}
            onClick={() => handleLanguageChange('en')}
          >
            English
          </button>
          <button
            className={`language-button ${language === 'ku' ? 'selected' : ''}`}
            onClick={() => handleLanguageChange('ku')}
          >
            کوردی
          </button>
        </div>

        <h1>{currentTranslation.title}</h1>

        <div className="exchange-rate">
          {isLoadingExchangeRate ? (
            <p>Loading exchange rate...</p>
          ) : exchangeRateError ? (
            <p style={{ color: 'red' }}>{exchangeRateError}</p>
          ) : (
            <p>1 USD = {exchangeRate} IQD</p>
          )}
        </div>

        <div className="form-group">
          <input
            type="text"
            placeholder={currentTranslation.customerName}
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
          />
          <input
            type="text"
            placeholder={currentTranslation.phoneNumber}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <input
            type="text"
            placeholder={currentTranslation.nameCode}
            value={nameCode}
            onChange={handleNameCodeChange}
            lang="ku"
          />
          {suggestions.length > 0 && (
            <div className="autocomplete-suggestions">
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="suggestion-item"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion.name} {suggestion.code}
                </div>
              ))}
            </div>
          )}

          <input
            type="number"
            placeholder={currentTranslation.packageQuantity}
            value={packageQuantity}
            onChange={(e) => setPackageQuantity(e.target.value)}
          />

          <input
            type="number"
            placeholder={currentTranslation.individualPrice}
            value={individualPrice}
            onChange={(e) => setIndividualPrice(e.target.value)}
            step="any"
          />

          <input
            type="number"
            placeholder={currentTranslation.packageAmount}
            value={packageAmount}
            onChange={(e) => setPackageAmount(e.target.value)}
          />

          <input
            type="number"
            placeholder={currentTranslation.discount}
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
          />

          <p>{currentTranslation.total}: {total} USD</p>
          <button className="add-listing" onClick={addListing}>
            {currentTranslation.addListing}
          </button>
        </div>

        {message && <p>{message}</p>}
        <button className="export-to-excel" onClick={exportToExcel}>
          {currentTranslation.exportToExcel}
        </button>
        {lastRemoved && (
          <button className="undo-button" onClick={undoRemove}>
            {currentTranslation.undo}
          </button>
        )}

        <div className="table-container">
          <table>
            <thead>
              <tr>
                {currentTranslation.headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {listings.map((listing, index) => (
                <tr key={index} className={`new-listing ${index % 2 === 0 ? "even" : "odd"}`}>
                  <td>{listing.customerName}</td>
                  <td>{listing.nameCode}</td>
                  <td>{listing.packageQuantity}</td>
                  <td>{listing.individualPrice}</td>
                  <td>{listing.packageAmount}</td>
                  <td>
                    <button
                      className="remove-button"
                      onClick={() => removeListing(index)}
                    >
                      {currentTranslation.removeListing}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      </div>
    </div>
  );
}

export default MyDashboard;
